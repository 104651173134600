import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import posthog from 'posthog-js'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

const posthogIntegration = posthog.sentryIntegration({
  organization: 'routescanner-jp',
  projectId: 4506338015969280
})

Sentry.init({
  dsn: 'https://47adbcb60489f58061bee37b6b9e09a8@o4506338009088000.ingest.us.sentry.io/4506338015969280',

  enabled: window.ENV.SENTRY_ENABLED,
  environment: window.ENV.ENVIRONMENT,
  tracesSampleRate: window.ENV.SENTRY_TRACE_RATE,
  replaysSessionSampleRate: window.ENV.SENTRY_REPLAY_RATE,
  replaysOnErrorSampleRate: window.ENV.SENTRY_REPLAY_ERROR_RATE,

  integrations: [
    // TODO: Use a dedicated POSTHOG_ENABLED env var for this
    ...(window.ENV.ENVIRONMENT === 'production' ? [posthogIntegration] : []),

    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    }),

    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true
    })
  ]
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})
